import React from 'react';

const Footer = () => {
    return (
        <footer className='footer'>
            <p>Coded by <a href='https://github.com/virgilt'>virgilt</a></p>
        </footer>
    )
}

export default Footer;